import React, { ComponentProps, ReactElement, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Profile from '_pages/people/[id]/profile'

import { Button, IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'

import ReportIncorrectDataDialog from '_core/components/dialogs/ReportIncorrectMarketData'
import { IsPrivateRelationshipBudge, MarkPrivateDialog, MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import DrawerMenu from '_core/components/DrawerMenu'
import { Middle, Narrow, NarrowStrict, SidepanelNarrow, SidepanelWide, useWide, Wide, WideStrict } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import MarketDataSelect, { MarketDataMenuButton } from '_core/components/MarketDataSelect'
import Line, { LinkLine } from '_core/components/ProfileLine'
import ProfileSummary from '_core/components/ProfileSummary'
import SidepanelLink from '_core/components/SidepanelLink'
import StatusIcon from '_core/components/StatusIcon'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { addMissedProtocol } from '_core/helpers/string'

import Paths from 'Paths'

const companyBaseUrl = Paths._companies
const salesForceUrl = Paths._salesforce

const useStyles = makeStyles()((theme) => ({
  summary: {
    marginBottom: theme.spacing(2)
  }
}))

const PersonHeader = (props: ComponentProps<typeof Profile>) => {
  const [privateRelationship, setPrivateRelationship] = useState<boolean | undefined>(undefined)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [tagsList, setTagsList] = useState<UngroupedTag[]>()

  const { classes } = useStyles()

  const { id } = useParams<{ id: string }>()

  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<'private' | 'public' | null>(null)
  const { isDialogOpened: openedReportDialog, openDialog: openReportDialog, closeDialog: closeReportDialog } = useDialog(false)

  const { payloads } = useSidepanelPayloads()

  const personName = props.PersonNameText || props.BestEmailAddrText

  useEffect(() => {
    setPrivateRelationship(props?.Privacy?.TurnedOffKeepPrivate ? false : !!props?.Privacy?.KeepPrivate)
  }, [props?.Privacy])

  useEffect(() => {
    const tagNames = props.Tags?.[0].TagNames
    if (!tagsList && tagNames) {
      setTagsList(
        tagNames.map(({ Category, Tag }: { Category: string; Tag: string } & { Md5: string }) => ({ categoryName: Category, tagName: Tag }))
      )
    }
  }, [props.Tags])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const { identifier, tags } = payloads.value[0]
        if (identifier === id) {
          setTagsList(tags)
        }
      }
    }
    if (tagsList) {
      if (payloads && payloads.action === 'SYNC_TAGS') {
        const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
        if (taggableType === 'people') {
          const nonDeprecated = tagsList.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          const renamed = nonDeprecated.map(({ categoryName, tagName }) => {
            const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

            const { newTagName = tagName } =
              tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
            return { categoryName: newCategoryName, tagName: newTagName }
          })
          setTagsList(renamed)
        }
      }
    }
  }, [payloads])

  const renderButtons =
    typeof props.isSalesforceEnabled === 'boolean' && typeof privateRelationship === 'boolean' && typeof props.enabledMarketData === 'boolean' && props.PersonMd5

  const upSmall = useWide('sm')

  const marketDataActions = useMemo(
    () =>
      [
        {
          name: 'Similar People',
          icon: ['fas', 'diagram-venn'],
          link: `/people/${id}/similar?name=${personName}`,
          condition: !props.showSimilarPeople
        },
        {
          name: 'Report incorrect data',
          icon: ['far', 'message-exclamation'],
          action: openReportDialog
        },
        {
          name: 'Learn more',
          icon: ['far', 'question-circle'],
          externalLink: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data'
        }
      ].filter((action) => (typeof action.condition === 'boolean' ? action.condition : true)),
    [id, personName, props.showSimilarPeople]
  )

  const handlePrivateOpen = () => {
    openDialog('private')
  }

  const handlePublicOpen = () => {
    openDialog('public')
  }

  const MarkPublicIconButton = (
    <MarkPublicDialog.TriggerIcon
      hint={`Mark your relationship ${personName ? `with ${personName}` : ''} as shared`}
      size="small"
      onClick={handlePublicOpen}
    />
  )

  const MarkPrivateIconButton = (
    <MarkPrivateDialog.TriggerIcon
      size="small"
      hint={`Mark your relationship ${personName ? `with ${personName}` : ''} as private`}
      onClick={handlePrivateOpen}
    />
  )

  const buttonsMap = [
    {
      name: 'Edit',
      icon: ['far', 'edit'],
      link: props.PersonMd5 ? `/people/${props.PersonMd5}/edit` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Merge',
      icon: ['far', 'merge'],
      link: props.PersonMd5 ? `${Paths._merge}/people?ids=${props.PersonMd5}` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Audit',
      icon: ['far', 'list-check'],
      link: props.PersonMd5 ? `${Paths._people}/${props.PersonMd5}/audit` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Save to Salesforce',
      icon: ['far', 'cloud-upload-alt'],
      link: props.PersonMd5 ? `${salesForceUrl}/contact/${props.PersonMd5}` : '',
      condition: props.isSalesforceEnabled && renderButtons,
      menuItem: !upSmall
    },
    {
      name: `Mark your relationship ${personName ? `with ${personName}` : ''} as shared`,
      icon: ['far', 'lock-open'],
      action: handlePublicOpen,
      condition: privateRelationship && !upSmall && renderButtons,
      menuItem: !upSmall
    },
    {
      name: `Mark your relationship ${personName ? `with ${personName}` : ''} as private`,
      icon: ['far', 'lock'],
      action: handlePrivateOpen,
      condition: !privateRelationship && !upSmall && renderButtons,
      menuItem: !upSmall
    },
    {
      name: 'Mark as public',
      component: (
        <>
          <Middle>{MarkPublicIconButton}</Middle>
          <WideStrict>
            <MarkPublicDialog.TriggerButton onClick={handlePublicOpen} />
          </WideStrict>
        </>
      ),
      condition: upSmall && privateRelationship && renderButtons
    },
    {
      name: 'Mark as private',
      component: (
        <>
          <Middle>{MarkPrivateIconButton}</Middle>
          <WideStrict>
            <MarkPrivateDialog.TriggerButton onClick={handlePrivateOpen} />
          </WideStrict>
        </>
      ),
      condition: upSmall && !privateRelationship && renderButtons
    }
  ].filter((button) => button.condition)

  const toggleDrawer = () => setDrawerOpen((prevState: boolean) => !prevState)

  const buttons = (
    <>
      <Wide>
        {props.enabledMarketData && renderButtons && <Item component={MarketDataSelect} item={{ menu: { actions: marketDataActions } }} disablePadding />}
      </Wide>

      {buttonsMap
        .filter((el) => !el.menuItem)
        .map((button: { [key: string]: any }) => {
          if (button.component) {
            return <Box key={button.name}>{button.component}</Box>
          }

          return (
            <SidepanelLink linkProps={{ to: button.link }} sidepanel={true} key={button.name}>
              <WideStrict>
                <Button color="primary" startIcon={<FontAwesomeIcon icon={button.icon} style={{ fontSize: 14 }} />}>
                  {button.name}
                </Button>
              </WideStrict>
              <Middle>
                <IconButton color="primary" hint={button.name} loading={!props.PersonMd5} icon={button.icon} size="small" />
              </Middle>
              <NarrowStrict>
                <IconButton color="primary" hint={button.name} loading={!props.PersonMd5} icon={button.icon} size="small" />
              </NarrowStrict>
            </SidepanelLink>
          )
        })}

      <Narrow>
        <SidepanelWide>
          {props.enabledMarketData && renderButtons && (
            <Box mr={-1}>
              <Item component={MarketDataMenuButton} item={{ menu: { actions: marketDataActions } }} disablePadding />
            </Box>
          )}
        </SidepanelWide>
        <SidepanelNarrow>
          <Box mr={-1}>
            <IconButton
              color="primary"
              icon={['far', 'ellipsis-v']}
              hint="Menu"
              size="small"
              loading={!props.PersonMd5}
              disablePX
              onClick={toggleDrawer}
            />
          </Box>
        </SidepanelNarrow>
      </Narrow>

      <MarkPrivateDialog
        opened={openedDialog === 'private'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={props.BestEmailAddrText ? [props.BestEmailAddrText] : []}
        userName={personName}
        setPrivateRelationship={setPrivateRelationship}
      />
      <MarkPublicDialog
        opened={openedDialog === 'public'}
        success={successMode}
        openSuccess={openSuccess}
        close={closeDialog}
        userKeys={props.BestEmailAddrText ? [props.BestEmailAddrText] : []}
        userName={personName}
        setPrivateRelationship={setPrivateRelationship}
      />
      <ReportIncorrectDataDialog opened={openedReportDialog} close={closeReportDialog} marketData={JSON.stringify(props.marketData)} />
    </>
  )

  const { linkedinUrl, githubUrl } = props.marketData || {}

  const actions = [
    (props.loading || props.BestPhoneText) && (
      <IconButton<'a'>
        component="a"
        icon={['far', 'phone']}
        color="primary"
        hint={`Make a call to ${props.BestPhoneText}`}
        disablePadding
        href={`tel:${props.BestPhoneText?.substring(2)}`}
        loading={!props.BestPhoneText}
        size="small"
      />
    ),
    (props.loading || props.BestEmailAddrText) && (
      <IconButton<'a'>
        component="a"
        icon={['far', 'envelope']}
        color="primary"
        hint={`Send an email to ${props.BestEmailAddrText}`}
        disablePadding
        href={`mailto:${props.BestEmailAddrText}`}
        loading={!props.BestEmailAddrText}
        size="small"
      />
    ),
    (props.loading || props.PersonNameText || linkedinUrl) && (
      <IconButton<'a'>
        component="a"
        icon={['fab', 'linkedin']}
        color="primary"
        hint="Show Linkedin profile"
        disablePadding
        href={linkedinUrl ? addMissedProtocol(linkedinUrl) : `https://www.linkedin.com/search/results/people/?keywords=${props.PersonNameText}`}
        loading={props.loading}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    ((props.enabledMarketData && !props.marketData) || (!props.loading && githubUrl)) && (
      <IconButton<'a'>
        component="a"
        icon={['fab', 'github']}
        color="primary"
        hint="Show Github profile"
        disablePadding
        href={githubUrl ? addMissedProtocol(githubUrl) : ''}
        loading={!githubUrl}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    )
  ].filter((action): action is ReactElement => !!action)

  const statusIcons = (
    <>
      {[
        {
          el: (
            <StatusIcon
              status={{ name: 'pending', title: 'Waiting for the next analytics run in order to reflect recent entity alignment changes.' }}
            />
          ),
          condition: !!props.AlignmentIsPending
        }
      ]
        .filter(({ condition }) => condition)
        .map(({ el }) => el)}
    </>
  )

  return (
    <>
      <Card elevation={0} className={classes.summary}>
        <CardContent>
          <ProfileSummary
            title={personName}
            loading={props.loading}
            budge={privateRelationship ? <IsPrivateRelationshipBudge userName={personName} /> : null}
            editTagsLink={`${Paths._people}/${id}/tags/edit?name=${personName}`}
            showAllTagsLink={`${Paths._people}/${id}/tags?name=${personName}`}
            score={props.Score}
            userKey={props.MyUserKeyMd5 ? props.BestEmailAddrText : ''}
            introducers={!props.MyUserKeyMd5 && props.Introducers ? props.Introducers : null}
            tags={tagsList}
            buttons={buttons}
            actions={actions}
            statusIcons={statusIcons}
            byline={<Line icon={['fas', 'user-tie']} loading={props.loading} value={props.BestJobTitleText} />}
            byline2={
              <LinkLine
                icon={['fas', 'building']}
                loading={props.loading}
                value={props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                to={`${companyBaseUrl}/${props.BestJobCompanyMd5}`}
              />
            }
          />
        </CardContent>
      </Card>
      <NarrowStrict>
        <DrawerMenu open={drawerOpen} toggleDrawer={toggleDrawer} items={props.enabledMarketData ? [...buttonsMap, ...marketDataActions] : buttonsMap} />
      </NarrowStrict>
    </>
  )
}

export default PersonHeader
