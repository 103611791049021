import { cloneElement, ReactElement, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import AvatarGroup from '_shared/AvatarGroup'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { Main } from '_core/components/Event'
import { useStyles as useEventCardStyles } from '_core/components/EventsList'
import { Controller as FilterController } from '_core/components/filters'
import { MeetingsFiltersType } from '_core/components/filters/Meetings'
import { DataGrid, NoRowsOverlay } from '_core/components/grid'
import { DateCell, dateColumn, nameColumn, TextCell, NameCell, SidepanelLinkCell } from '_core/components/grid/columns'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import { Controller as SortController } from '_core/components/sort'
import Sort, { MeetingsSortProps, sortMap } from '_core/components/sort/Meetings'

import { Meeting } from '_core/hooks/useContributorsMeetings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanel from '_core/hooks/useSidepanel'

import { checkOutlook } from '_core/helpers/outlook'

import { formatDate, formatDateTime, formatTime, getLocal } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles<{ filtersOpened: boolean }>()((theme, { filtersOpened }) => ({
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  }
}))

const startDateColumn: GridColDef = {
  ...dateColumn,
  field: 'startDate',
  headerName: 'Start date',
  renderCell: (params: GridRenderCellParams) => {
    const { startDate } = params.row
    const formatted = formatDateTime(startDate)
    return startDate ? <DateCell value={formatted} label={formatted} /> : <Typography color="text.secondary">no date</Typography>
  }
}
const endDateColumn: GridColDef = {
  ...dateColumn,
  field: 'endDate',
  headerName: 'End date',
  sortable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { endDate } = params.row
    const formatted = formatDateTime(endDate)
    return endDate ? <DateCell value={formatted} label={formatted} /> : <Typography color="text.secondary">no date</Typography>
  }
}

const subjectColumn: GridColDef = {
  field: 'subject',
  headerName: 'Subject',
  width: 250,
  minWidth: 100,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { subject, link, onClick } = row
    return <SidepanelLinkCell value={subject} id={id} onClick={onClick} link={link} sidepanel />
  }
}

const organizerColumn: GridColDef = {
  ...nameColumn,
  width: 200,
  minWidth: 200,
  field: 'organizer',
  headerName: 'Organizer',
  renderCell: (params: GridRenderCellParams) => {
    const { name, email, id } = params.row.organizer || {}
    return <NameCell {...params} value={name} row={{ ...params.row, name, email, link: `${Paths._people}/${id}` }} />
  }
}

const locationColumn: GridColDef = {
  field: 'location',
  headerName: 'Location',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.location} />
}

const participantsColumns: GridColDef = {
  field: 'participants',
  headerName: 'Participants',
  minWidth: 130,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const [detailsPath, detailsSearch] = params.row.link?.split('?') || []
    return (
      <Box px={0.5}>
        <AvatarGroup
          alignRight
          max={3}
          skeleton={{ size: 3, loading: false }}
          hideName
          sidepanel
          size="xs"
          seeAllLink={`${detailsPath}/participants?${detailsSearch}`}
          items={params.row.participants.map((item: { id: string; name: string; email: string; companyMd5: string }) => ({
            name: item.name,
            userKey: item.email,
            link: `${Paths._people}/${item.id || item.email}`
          }))}
        />
      </Box>
    )
  }
}

export const columns = [startDateColumn, endDateColumn, subjectColumn, organizerColumn, participantsColumns, locationColumn]

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<MeetingsFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
  sortProps: Pick<MeetingsSortProps, 'items' | 'sort' | 'updateSort'>
}

export const Heading = ({ filtersProps, sortProps, filters }: HeadingProps) => {
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps

  const { classes } = useStyles({ filtersOpened })

  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()

  const anchorRef = useRef<HTMLDivElement | null>(null)

  const [sortCollapsed, setSortCollapsed] = useState(true)

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  return (
    <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <div ref={anchorRef}>
          <Box display="flex">
            <Box className={classes.icons}>
              <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} disabled={disabled} className={classes.displayVariant} />
              <FilterController disablePR opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
            </Box>
          </Box>
        </div>
        {cloneElement(filters, { anchorEl: anchorRef.current })}
        <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
      </CardContent>
    </Card>
  )
}

const EventCard = (props: Partial<Meeting & { seeAllParticipantLink: string }>) => {
  const matchPhoneWidth = useWide('phone')
  const { classes, cx } = useEventCardStyles()

  const { openedUrl } = useSidepanel()
  const selected = openedUrl.indexOf(`${Paths._events}/${props.id}`) > -1

  const loading = !props.id

  const { organizer } = props || {}

  const isTodaysEvent = getLocal(props.startDate).startOf('day').diff(getLocal().startOf('day'), 'days') === 0

  return (
    <Card className={cx({ [classes.selected]: selected })} round variant="outlined">
      <CardContent>
        <Columns spacing={0}>
          <Column xs={matchPhoneWidth ? 8 : 12} md={8}>
            <Skeleton condition={loading} width={120} height={28}>
              <Typography semiBold color="text.secondary">
                {!isTodaysEvent && (
                  <>
                    <span>{formatDate(props.startDate, 'ddd D, MMM ')}</span>⋅{' '}
                  </>
                )}

                <span>
                  {formatTime(props.startDate)}
                  {props.endDate && <> - {formatTime(props.endDate)}</>}{' '}
                </span>
              </Typography>
            </Skeleton>
            <Main
              className={classes.main}
              loading={loading}
              subject={props.subject}
              location={props.location}
              organizer={organizer ? { name: organizer.name, md5: organizer.id, email: organizer.email } : undefined}
            />
          </Column>
          {matchPhoneWidth && (
            <Column xs={4} md={4}>
              <AvatarGroup
                alignRight
                max={3}
                skeleton={{ size: 3, loading }}
                hideName={true}
                sidepanel="preview"
                size="xs"
                seeAllLink={props.seeAllParticipantLink}
                items={
                  props.participants?.map((item) => ({
                    name: item.name,
                    userKey: item.email,
                    link: `${Paths._people}/${item.id || item.email}`
                  })) || []
                }
              />
            </Column>
          )}
        </Columns>
      </CardContent>
    </Card>
  )
}

const MeetingsList = (props: {
  meetings: Meeting[] | undefined
  total: number
  loading: boolean
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (value: SortType) => void
  downloadAllControl: ReactElement
  enableDownloading: boolean | null
}) => {
  const { queryParams } = useSearchQuery<MeetingsPageParams, { modifyProps: [{ ids: string[]; checked: IncludeMeetingsType[] }] }>(['ids', 'checked'])
  const { sort = 'NewestToOldest', page, rowsPerPage = '10', ids } = queryParams

  const sortModels = (Object.keys(sortMap) as (keyof typeof sortMap)[]).map((sortKey) => ({
    field: sortKey,
    sort: (sort === sortMap[sortKey].asc ? 'asc' : 'desc') as GridSortDirection
  }))

  const updateSort = (model: GridSortModel) => {
    if (model?.length && queryParams.sort) {
      const { field, sort: newSort } = model[0]
      if (newSort && queryParams.sort !== sortMap[field][newSort]) {
        props.updateSort(sortMap[field][newSort])
      }
    }
  }

  const controls =
    typeof props.enableDownloading === 'boolean'
      ? [{ control: props.downloadAllControl, condition: props.enableDownloading }]
          .filter((item) => (typeof item.condition === 'boolean' ? item.condition : true))
          .map((item) => item.control)
      : []

  return (
    <>
      <Wide>
        <DataGrid
          columns={columns}
          rows={props.meetings || []}
          controls={controls}
          components={!ids?.length ? { NoRowsOverlay: () => <NoRowsOverlay text="Pick contributors to see their meetings." /> } : {}}
          loading={props.loading}
          sortModel={sortModels}
          setSortModel={updateSort}
          paging={{ page: page ? +page : 1, size: rowsPerPage }}
          setPageSize={props.onPageSizeChange}
          total={props.total}
        />
      </Wide>
      <Narrow>
        <Box px={2}>
          <Repeater
            items={
              props.meetings?.map((meeting) => {
                const [detailsPath, detailsSearch] = meeting.link?.split('?') || []
                return { ...meeting, seeAllParticipantLink: `${detailsPath}/participants?${detailsSearch}` }
              }) || []
            }
            skeleton={{ loading: props.loading, size: 10 }}
            component={EventCard}
          />
        </Box>
      </Narrow>
    </>
  )
}

export default MeetingsList
