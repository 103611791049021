import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import moment from 'moment'

import { ActivitiesPeriodSupportContext, ContextType as ActivitiesPeriodSupportContextType } from '_core/context/ActivitiesPeriodSupport'
import { TeamContext } from '_core/context/TeamContext'

import { ActivitiesListItem, ActivityDataType } from '_core/components/ActivitiesList'

import { request } from 'utils/fetchUtils'
import { transformBody } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

export type ActivityStatsMonth = { year: number; month: number; minDay: number; maxDay: number }

const useActivitiesStats = (
  plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
  groupByMd5s: string[],
  months: ActivityStatsMonth[]
) => {
  const { teamContextValue } = useContext(TeamContext)
  const activitiesPeriodSupport = useContext(ActivitiesPeriodSupportContext)
  const [{ loading, stats }, setData] = useState<{ loading: boolean; stats?: Pick<ActivitiesListItem, 'inbound' | 'outbound' | 'meetings'>[] }>({
    loading: false
  })

  const abortRef = useRef<null | AbortController>(null)

  const clearStats = () => {
    setData({ loading: false })
  }

  const getStats = useCallback(
    async (
      plds: { [key in 'inbound' | 'outbound' | 'meetings']: { [key: string]: string | string[] } } | null,
      groupByMd5s: string[],
      months: { year: number; month: number; minDay: number; maxDay: number }[],
      activitiesPeriodSupport: Exclude<ActivitiesPeriodSupportContextType, undefined>
    ) => {
      if (plds) {
        const { emailsFromMonthsAgo, emailsUntilMonthsFromNow, meetingsFromMonthsAgo, meetingsUntilMonthsFromNow } = activitiesPeriodSupport

        const minMeetings = getLocal().add(-meetingsFromMonthsAgo, 'months').startOf('month')
        const maxMeetings = getLocal().add(meetingsUntilMonthsFromNow, 'months').endOf('month')

        const availableMeetingsMonthsIdxs = months.reduce((indexes, { minDay, month, year }, idx) => {
          if (
            moment({ year, month: month - 1, day: minDay }).diff(minMeetings) >= 0 &&
            moment({ year, month: month - 1, day: minDay }).diff(maxMeetings) <= 0
          ) {
            indexes.push(idx)
          }
          return indexes
        }, [] as number[])

        const meetingsPayload = {
          periods: availableMeetingsMonthsIdxs.map((idx) => {
            const { year, month, minDay, maxDay } = months[idx]
            return {
              from: getLocal({ year, month: month - 1, day: minDay })
                .startOf('day')
                .toISOString(),
              until: getLocal({ year, month: month - 1, day: maxDay })
                .endOf('day')
                .toISOString()
            }
          }),
          ...(plds.meetings || {})
        }

        const localHoursShift = -(getLocal().utcOffset() / 60)
        const minEmails = getLocal().add(-emailsFromMonthsAgo, 'months').startOf('month')
        const maxEmails = getLocal().add(emailsUntilMonthsFromNow, 'months').endOf('month')

        const availableEmailsMonthsIdxs = months.reduce((indexes, { minDay, month, year }, idx) => {
          if (
            moment({ year, month: month - 1, day: minDay }).diff(minEmails) >= 0 &&
            moment({ year, month: month - 1, day: minDay }).diff(maxEmails) <= 0
          ) {
            indexes.push(idx)
          }
          return indexes
        }, [] as number[])

        const availableEmailsMonths = availableEmailsMonthsIdxs.map((index) => months[index])

        const inboundPayload = {
          months: availableEmailsMonths,
          localHoursShift,
          ...(plds.inbound || {})
        }

        const outboundPayload = {
          months: availableEmailsMonths,
          localHoursShift,
          ...(plds.outbound || {})
        }

        const requests = [
          {
            url: '/histostats/meetings',
            payload: meetingsPayload,
            idxsMap: availableMeetingsMonthsIdxs,
            condition: availableMeetingsMonthsIdxs.length
          },
          {
            url: '/histostats/emails',
            payload: inboundPayload,
            idxsMap: availableEmailsMonthsIdxs,
            condition: availableEmailsMonthsIdxs.length
          },
          {
            url: '/histostats/emails',
            payload: outboundPayload,
            idxsMap: availableEmailsMonthsIdxs,
            condition: availableEmailsMonthsIdxs.length
          }
        ]

        const abortController = new AbortController()
        abortRef.current = abortController

        const createRequest = ({ url, payload }: (typeof requests)[number]) => {
          return request<ActivitiesHistoStatsRespType>(`${url}?teamNumber=${teamContextValue.teamNumber}`, {
            method: 'POST',
            body: transformBody({
              ...payload,
              withMeetingTags: true,
              havingMeetingTags: '',
              lackingMeetingTags: '',
              skip: 0,
              take: 100
            }),
            ...(abortRef.current ? { signal: abortRef.current.signal } : {})
          })
        }

        const responses = await Promise.all(requests.filter(({ condition }) => condition).map(createRequest))

        if (!responses.includes(undefined)) {
          const [meetings, inbound, outbound] = responses.reduce(
            (acc, data = [], idx) => {
              data.forEach(({ results }, k) => {
                acc[idx % requests.length] = {
                  ...acc[idx % requests.length],
                  ...results.reduce(
                    (dAcc, d) => {
                      const accPer3 = acc[idx % requests.length]?.[d.groupedBy]

                      if (!accPer3) {
                        return {
                          ...dAcc,
                          [d.groupedBy]: {
                            byIndex: { [requests[idx].idxsMap[k]]: d.count },
                            count: d.count,
                            groupedBy: d.groupedBy
                          }
                        }
                      }

                      return {
                        ...dAcc,
                        [d.groupedBy]: {
                          byIndex: { ...accPer3.byIndex, [k]: d.count },
                          count: accPer3.count + d.count,
                          groupedBy: d.groupedBy
                        }
                      }
                    },
                    {} as { [groupedBy: string]: ActivityDataType }
                  )
                }
              })
              return acc
            },
            [] as ({ [groupedBy: string]: ActivityDataType } | undefined)[]
          )

          const result = groupByMd5s.map((key) => ({
            meetings: meetings ? meetings[key] : null,
            inbound: inbound ? inbound[key] : null,
            outbound: outbound ? outbound[key] : null
          })) as ActivitiesListItem[] | null

          return result
        }
      }
    },
    [teamContextValue.teamNumber]
  )

  useEffect(() => {
    return () => {
      clearStats()
      if (abortRef.current) {
        abortRef.current.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [months])

  useEffect(() => {
    if (plds && groupByMd5s.length && months.length && activitiesPeriodSupport) {
      ;(async () => {
        setData({ loading: true })
        const stats = await getStats(plds, groupByMd5s, months, activitiesPeriodSupport)
        if (stats) {
          setData({ loading: false, stats })
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStats, groupByMd5s, plds, months, !!activitiesPeriodSupport])

  return { loading, stats, clearStats }
}

export default useActivitiesStats
