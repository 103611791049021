import { cloneElement, ReactElement, useRef, Fragment } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import AvatarGroup from '_shared/AvatarGroup'
import IconButton from '_shared/buttons/Icon'
import Card, { CardContent } from '_shared/Card'
import Icon from '_shared/Icon'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { PersonActivitiesFiltersType, Controller as FilterController } from '_core/components/filters/PersonActivities'
import InternalTag from '_core/components/InternalTag'
import { Column, Columns } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import NameLink from '_core/components/NameLink'
import SidepanelLink from '_core/components/SidepanelLink'

import { checkOutlook } from '_core/helpers/outlook'

import { formatDate, formatDateTime, formatTime, getLocal } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles<void | Partial<{ containerWidth: number }>>()((theme) => ({
  hiddenActivitySlot: {
    opacity: '0'
  },
  column: {
    '&:last-of-type': {
      alignItems: 'flex-end'
    }
  },
  date: {
    marginBottom: theme.spacing(1)
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1
  },
  filterWidget: {
    borderBottom: '1px solid #f2f2f2'
  }
}))

type PersonActivitiesParticipant = { id: string; name: string; email: string; companyMd5: string }

type ContactActivityItemProps = {
  from?: PersonActivitiesParticipant[]
  to?: PersonActivitiesParticipant[]
  dateTime?: string
  className?: string
  detailsLink: string | null
  isInternal: boolean
  actionText?: string
  icon?: IconProp
  companies: string[]
  showDate: boolean
}

type PersonActivitiesProps = {
  activities?: Omit<ContactActivityItemProps, 'showDate'>[]
}

export const Heading = ({
  filters,
  filtersProps,
  sortProps
}: {
  filters: ReactElement
  filtersProps: Pick<PersonActivitiesFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
  sortProps: { sort: EntityActivitiesPageParams['sort']; toggle: () => void }
}) => {
  const { classes, cx } = useStyles()
  const { opened: filtersOpened, toggleOpen: toggleFilterOpen, disabled } = filtersProps
  const { sort, toggle: toggleSort } = sortProps
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)

  return (
    <Card elevation={0} square className={cx(autoHideClassName, classes.filterWidget)} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <div ref={anchorRef}>
          <Box display="flex" alignItems="center" my={-1}>
            <Typography variant="h4" semiBold>
              Filters
            </Typography>
            <Box className={classes.icons}>
              <IconButton
                disableRipple
                color="primary"
                hint="Sort"
                size="small"
                onClick={toggleSort}
                icon={['far', sort === 'OldestToNewest' ? 'arrow-up-wide-short' : 'arrow-down-wide-short']}
                disabled={disabled}
              />
              <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} disablePR />
            </Box>
          </Box>
        </div>
        {cloneElement(filters, { anchorEl: anchorRef.current })}
      </CardContent>
    </Card>
  )
}

const ContactActivityItem = (props: ContactActivityItemProps) => {
  const loading = !props.from

  const { from = [], to = [], dateTime, actionText, icon, isInternal, detailsLink, companies = [], className, showDate } = props
  const { classes, cx } = useStyles()
  const [detailsPath, detailsSearch] = detailsLink?.split('?') || []

  const fromCutted = from.slice(0, 2)
  const fromDiff = from.length - fromCutted.length

  const toCutted = to.slice(0, 2)
  const toDiff = to.length - toCutted.length

  return (
    <>
      {showDate && (
        <Typography color="text.secondary" semiBold classes={{ root: classes.date }}>
          {formatDate(dateTime)}
        </Typography>
      )}
      <SidepanelLink linkProps={{ to: detailsLink || '' }}>
        <Card className={cx({ [classes.hiddenActivitySlot]: !actionText && !loading }, className)}>
          <CardContent>
            <Columns spacing={0}>
              <Column md={6} xs={6}>
                {icon && <Icon icon={icon} contained containerSize="lg" />}
              </Column>
              <Column md={6} xs={6} className={classes.column}>
                {!loading && <InternalTag label={isInternal ? 'Internal' : 'External'} />}
              </Column>
            </Columns>
            <Box pt={0.5} pb={1}>
              <Skeleton condition={loading}>
                <Tooltip title={formatDateTime(dateTime)} placement="top-start">
                  <Typography color="text.secondary">{formatTime(dateTime)}</Typography>
                </Tooltip>
              </Skeleton>
              <Skeleton condition={loading}>
                <Typography>
                  {fromCutted.map(({ id, name }, i) => (
                    <Fragment key={id || i}>
                      <NameLink url={`${Paths._people}/${id}`} name={name} />
                      {fromDiff === 0 && from.length - i === 2 ? ' and ' : fromCutted.length - i > 1 ? ', ' : ' '}
                    </Fragment>
                  ))}
                  {fromDiff > 0 && `and ${fromDiff} more ${fromDiff > 1 ? 'people' : 'person'} `}
                  {actionText || 'long placeholder'}{' '}
                  {toCutted.map(({ id, name }, i) => (
                    <Fragment key={id || i}>
                      <NameLink url={`${Paths._people}/${id}`} name={name} />
                      {toDiff === 0 && to.length - i === 2 ? ' and ' : toCutted.length - i > 1 ? ', ' : ''}
                    </Fragment>
                  ))}
                  {toDiff > 0 && `and ${toDiff} more ${toDiff > 1 ? 'people' : 'person'} `}
                </Typography>
              </Skeleton>
            </Box>
            <Columns spacing={0}>
              <Column md={6} xs={6}>
                <AvatarGroup
                  max={3}
                  skeleton={{ size: 3, loading }}
                  hideName={true}
                  sidepanel={true}
                  size="xs"
                  seeAllLink={detailsPath && detailsSearch ? `${detailsPath}/participants?${detailsSearch}` : ''}
                  items={[...from.slice(0, 1), ...to.slice(0, 1), ...from.slice(1), ...to.slice(1)].map((item) => ({
                    name: item.name,
                    PersonKey: item.email,
                    link: `${Paths._people}/${item.id}`
                  }))}
                />
              </Column>
              <Column md={6} xs={6} className={classes.column}>
                <AvatarGroup
                  max={3}
                  skeleton={{ size: 3, loading }}
                  hideName={true}
                  sidepanel={true}
                  size="xs"
                  seeAllLink={detailsPath && detailsSearch ? `${detailsPath}/companies${detailsSearch}` : ''}
                  items={companies.map((domain) => ({
                    name: domain,
                    logoUrl: domain,
                    link: `${Paths._companies}/${domain}`
                  }))}
                />
              </Column>
            </Columns>
          </CardContent>
        </Card>
      </SidepanelLink>
    </>
  )
}

const ContactActivitiesList = (props: PersonActivitiesProps) => {
  return (
    <Container>
      <Repeater
        virtualized
        items={
          props.activities?.reduce(
            (acc, activity, index) => [
              ...acc,
              { ...activity, showDate: index > 0 ? !getLocal(acc[index - 1].dateTime).isSame(getLocal(activity.dateTime), 'day') : true }
            ],
            [] as (Exclude<PersonActivitiesProps['activities'], undefined>[number] & { showDate: boolean })[]
          ) || []
        }
        skeleton={{ loading: !props.activities, size: 15 }}
        component={ContactActivityItem}
      />
    </Container>
  )
}

export default ContactActivitiesList
