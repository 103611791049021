import React, { cloneElement, ReactElement, useRef } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'

import { MarketDataFilters, Controller as FilterController } from '_core/components/filters/MarketDataPeopleSearch'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import SearchInput from '_core/components/SearchInput'

import { checkOutlook } from '_core/helpers/outlook'

export type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<MarketDataFilters, 'opened' | 'disabled' | 'toggleOpen'>
  viewProps: {
    viewMode?: ViewModeType
    updateViewMode: (val: ViewModeType) => void
  }
}

const useStyles = makeStyles<{ filtersOpened?: boolean }>()((theme, { filtersOpened }) => ({
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened ? 39 : 39 * 2,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: filtersOpened ? 'calc(100% - 39px)' : `calc(100% - ${39 * 2}px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  }
}))

const MarketDataSearchHeading = ({
  filtersProps,
  filters,
  filtersCount,
  viewProps,
  placeholder
}: HeadingProps & { filtersCount: number; placeholder: string }) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const { classes } = useStyles({ filtersOpened })
  const { viewMode, updateViewMode } = viewProps

  const toggleView = () => updateViewMode(viewMode === 'collapsed' ? 'expanded' : 'collapsed')

  return (
    <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <div ref={anchorRef}>
          <Box display="flex">
            <SearchInput disabled={disabled} variant="collapsed" placeholder={placeholder} opened wrapperClassName={classes.input} />
            <Box className={classes.icons}>
              <IconButton
                hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
                onClick={toggleView}
                size="small"
                icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
                className={classes.displayVariant}
                disabled={disabled}
              />
              <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} filtersCount={filtersCount} />
            </Box>
          </Box>
        </div>
        {cloneElement(filters, { anchorEl: anchorRef.current })}
      </CardContent>
    </Card>
  )
}

export default MarketDataSearchHeading
