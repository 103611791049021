import React, { useRef, useState, useMemo, useCallback, Fragment, ReactElement, cloneElement } from 'react'

import { Box } from '@mui/material'
import { GridSortModel, GridSortDirection, GridRenderCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import AvatarGroup from '_shared/AvatarGroup'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { Controller as FilterController, DealsFiltersType } from '_core/components/filters/Deals'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { TextCell, DateCell } from '_core/components/grid/columns'
import GridHeadingButtonsContent, { GridHeadingButtonType } from '_core/components/GridHeadingButtons'
import { Narrow, Wide, Column, Columns, useWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import NameLink from '_core/components/NameLink'
import SearchInput from '_core/components/SearchInput'
import Sort, { sortMap, DealsSortProps, Controller as SortController } from '_core/components/sort/Deals'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { getLanguage } from '_core/helpers/browser'
import { checkOutlook } from '_core/helpers/outlook'

import { getLocal, formatDate } from 'utils/Utils'

import Paths from 'Paths'

import { useAutoHideOnScrollStyles } from './layout/autohide-on-scroll'

const useStyles = makeStyles<{ filtersOpened?: boolean }>()((theme, { filtersOpened }) => ({
  input: {
    marginRight: theme.spacing(0.5),
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened ? 39 : 39 * 2,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  },
  dateHeader: {
    marginBottom: theme.spacing(0.5)
  },
  dateLabel: {
    width: 'fit-content'
  }
}))

type DateField = keyof Pick<DealItemProps, 'closedDate' | 'engagedDate' | 'renewalDate'>

type HeadingProps = {
  sortProps: Pick<DealsSortProps, 'items' | 'value' | 'update'>
  filters: ReactElement
  filtersProps: Pick<DealsFiltersType, 'opened' | 'disabled'> & {
    toggleOpen: () => void
  }
  searchPlaceholder: string
}

export const Heading = ({ sortProps, filtersProps, searchPlaceholder, filters }: HeadingProps) => {
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps

  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)

  const { classes } = useStyles({ filtersOpened })

  const [sortCollapsed, setSortCollapsed] = useState(true)

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  return (
    <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <div ref={anchorRef}>
          <Box display="flex">
            <SearchInput disabled={disabled} placeholder={searchPlaceholder} variant="collapsed" opened wrapperClassName={classes.input} />
            <Box className={classes.icons}>
              <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} disabled={disabled} className={classes.displayVariant} />
              <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
            </Box>
          </Box>
        </div>
        {cloneElement(filters, { anchorEl: anchorRef.current })}
        <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
      </CardContent>
    </Card>
  )
}

export const GridHeadingButtons = () => {
  const { isAdminOrCurator } = useAdminOrCuratorCheck()

  const actions: GridHeadingButtonType[] = [
    {
      label: 'Upload deals',
      icon: ['far', 'cloud-arrow-up'],
      link: `${Paths._deals}/upload`,
      condition: !!isAdminOrCurator
    }
  ]

  return <GridHeadingButtonsContent actions={actions.filter((action) => (typeof action.condition === 'boolean' ? action.condition : true))} />
}

type DealItemProps = {
  id: string
  synopsis: string
  colleagues: { [key: string]: string }[]
  typeName: string
  stageName: string
  ourRoleName: string
  company: { name: string; link: string }
  dates: { headerName?: string; field: string }[]
  renewalDate: string
  engagedDate: string
  closedDate: string
}

const DealItem = (props: DealItemProps) => {
  const { classes, cx } = useStyles({})
  const matchPhoneWidth = useWide('phone')
  const { id, synopsis, colleagues = [], typeName, stageName, ourRoleName, company, dates } = props
  const loading = !id

  return (
    <Card variant="outlined" round>
      <CardContent>
        <Columns spacing={0}>
          <Column xs={!matchPhoneWidth ? 12 : 8} md={8}>
            <Typography variant="h4" semiBold noWrap>
              {synopsis}
            </Typography>
            {company && company.name && (
              <Box mb={loading ? 0 : 1}>
                <NameLink variant="light" url={company.link} name={company.name} />
              </Box>
            )}
            {[typeName, stageName, ourRoleName].map((value, i) => (
              <Skeleton key={i} condition={loading} width={150}>
                <Typography key={i} noWrap>
                  {loading ? 'placeholder' : value}
                </Typography>
              </Skeleton>
            ))}
            {!loading &&
              dates.map(({ headerName, field }) => (
                <Fragment key={field}>
                  {(props[field as DateField] || loading) && (
                    <Box mt={loading ? 0 : 1}>
                      <Typography color="text.secondary" semiBold className={cx({ [classes.dateHeader]: !loading })}>
                        {headerName}
                      </Typography>
                      <Tooltip title={formatDate(props[field as DateField])}>
                        <Typography className={classes.dateLabel}>{formatDate(props[field as DateField])}</Typography>
                      </Tooltip>
                    </Box>
                  )}
                </Fragment>
              ))}
          </Column>
          <Column xs={!matchPhoneWidth ? 12 : 4} md={4}>
            <AvatarGroup
              alignRight
              max={3}
              skeleton={{ size: 3, loading: !id }}
              hideName
              sidepanel
              size="xs"
              seeAllLink={`${Paths._deals}/${id}/colleagues`}
              items={colleagues.map((item: { [key: string]: string }) => ({
                name: item.UserFullName,
                userKey: item.UserEmail,
                link: `${Paths._people}/${item.UserKeyMd5}`
              }))}
            />
          </Column>
        </Columns>
      </CardContent>
    </Card>
  )
}

export type DealsListProps = {
  items: { [key: string]: any }[]
  loading: boolean
  updateSort: (val: DealsSortType) => void
  columns: GridColDef[]
} & GridPaginationType

const DealsList = (props: DealsListProps) => {
  const { queryParams } = useSearchQuery<DealsPageParams>()
  const { sort } = queryParams

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)
  const sortModels = (Object.keys(sortMap) as (keyof typeof sortMap)[]).map((sortKey) => ({
    field: sortKey,
    sort: (sort === sortMap[sortKey].asc ? 'asc' : 'desc') as GridSortDirection
  }))

  const items = useMemo(
    () =>
      props.items.map((deal: Record<string, any>) => ({
        announcedDate: deal.DateAnnounced,
        renewalDate: deal.DateOfRenewal,
        closedDate: deal.DateClosed,
        engagedDate: deal.DateOfEngagement,
        deal: deal.Deal,
        id: deal.DealIdentifier,
        stage: deal.DealStage,
        stageName: deal.DealStageName,
        type: deal.DealType,
        typeName: deal.DealTypeName,
        editDate: deal.EditDate,
        ledgerName: deal.LedgerName,
        ourCompanyMd5: deal.OurCompanyMd5,
        ourRoleName: deal.OurRoleName,
        sourceTally: deal.SourceTally,
        synopsis: deal.Synopsis,
        company: {
          name: deal.TheirCoName,
          link: `${Paths._companies}/${deal.TheirCompanyMd5}`,
          sidepanel: true,
          url: deal.TheirUrl
        },
        totalDealValueUsd: deal.TotalDealValueUsd,
        weKnowCompany: deal.WeKnowCompany,
        colleagues: deal.Colleagues?.data || []
      })),
    [props.loading]
  )

  const updateSort = (model: GridSortModel) => {
    if (model?.length) {
      const { field, sort: newSort } = model[0]
      if (newSort && queryParams.sort !== sortMap[field][newSort]) {
        const sort = sortMap[field][newSort]
        props.updateSort(sort)
      }
    }
  }

  const dates = useMemo(() => props.columns.filter(({ type }) => type === 'dateTime'), [props.columns])

  return (
    <>
      <Wide>
        <DataGrid
          rows={items}
          columns={props.columns}
          setSortModel={updateSort}
          sortModel={sortModels.filter((m) => m.field === sortByField)}
          controls={[]}
          loading={props.loading}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={props.total}
        />
      </Wide>
      <Narrow>
        <Box px={2}>
          <Repeater
            direction="vertical"
            component={useCallback(
              (props: Omit<DealItemProps, 'dates'>) => (
                <DealItem dates={dates} {...props} />
              ),
              [dates]
            )}
            skeleton={{ size: 10, loading: props.loading }}
            items={items}
          />
        </Box>
      </Narrow>
    </>
  )
}

export default DealsList

export const dealType: GridColDef = {
  field: 'type',
  headerName: 'Type',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.typeName} />
}

export const dealStageColumn: GridColDef = {
  field: 'stage',
  headerName: 'Stage',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.stageName} />
}

export const ourRoleColumn: GridColDef = {
  field: 'ourRole',
  headerName: 'Our Role',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.ourRoleName} />
}

export const engagedColumn: GridColDef = {
  field: 'engagedDate',
  headerName: 'Engaged Date',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  type: 'dateTime',
  valueGetter: (value: GridValueGetterParams) => getLocal(value.row.engagedDate),
  renderCell: (params: GridRenderCellParams) => {
    const { engagedDate } = params.row
    return <DateCell value={engagedDate ? formatDate(engagedDate) : ''} label={engagedDate ? formatDate(engagedDate) : ''} />
  }
}

export const closedColumn: GridColDef = {
  field: 'closedDate',
  headerName: 'Closed Date',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  type: 'dateTime',
  valueGetter: (value: GridValueGetterParams) => getLocal(value.row.closedDate),
  renderCell: (params: GridRenderCellParams) => {
    const { closedDate } = params.row
    return <DateCell value={closedDate ? formatDate(closedDate) : ''} label={closedDate ? formatDate(closedDate) : ''} />
  }
}

export const renewalColumn: GridColDef = {
  field: 'renewalDate',
  headerName: 'Renewal Date',
  minWidth: 120,
  flex: 0.6,
  sortable: true,
  filterable: false,
  type: 'dateTime',
  valueGetter: (value: GridValueGetterParams) => getLocal(value.row.renewalDate),
  renderCell: (params: GridRenderCellParams) => {
    const { renewalDate } = params.row
    return <DateCell value={renewalDate ? formatDate(renewalDate) : ''} label={renewalDate ? formatDate(renewalDate) : ''} />
  }
}

export const valueUSColumn: GridColDef = {
  field: 'totalDealValueUsd',
  headerName: 'Value US$',
  minWidth: 100,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const totalUSD = new Intl.NumberFormat(getLanguage(), { style: 'currency', currency: 'USD' }).format(params.row.totalDealValueUsd)
    return <TextCell value={totalUSD} />
  }
}

export const synopsisColumn: GridColDef = {
  field: 'synopsis',
  headerName: 'Synopsis',
  minWidth: 100,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.synopsis} />
}

export const colleaguesColumn: GridColDef = {
  field: 'colleagues',
  headerName: 'Colleagues',
  minWidth: 130,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <Box px={0.5}>
      <AvatarGroup
        alignRight
        max={3}
        skeleton={{ size: 3, loading: false }}
        hideName
        sidepanel
        size="xs"
        seeAllLink={`${Paths._deals}/${params.row.id}/colleagues`}
        items={params.row.colleagues.map((item: { [key: string]: string }) => ({
          name: item.UserFullName,
          userKey: item.UserEmail,
          link: `${Paths._people}/${item.UserKeyMd5 || item.PersonMd5 || item.UserEmail}`
        }))}
      />
    </Box>
  )
}
