import React, { ComponentProps, ReactElement, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Profile from '_pages/companies/[id]/profile'

import { Button, IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'

import ReportIncorrectDataDialog from '_core/components/dialogs/ReportIncorrectMarketData'
import DrawerMenu from '_core/components/DrawerMenu'
import { Middle, Narrow, NarrowStrict, SidepanelNarrow, SidepanelWide, useWide, Wide, WideStrict } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import MarketDataSelect, { MarketDataMenuButton } from '_core/components/MarketDataSelect'
import { AnchorLinkLine } from '_core/components/ProfileLine'
import ProfileSummary from '_core/components/ProfileSummary'
import SidepanelLink from '_core/components/SidepanelLink'
import StatusIcon from '_core/components/StatusIcon'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { addMissedProtocol } from '_core/helpers/string'

import Paths from 'Paths'

const salesForceUrl = Paths._salesforce

const useStyles = makeStyles()((theme) => ({
  summary: {
    marginBottom: theme.spacing(2)
  }
}))

const CompanyHeader = (props: ComponentProps<typeof Profile>) => {
  const { id } = useParams<{ id: string }>()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [tagsList, setTagsList] = useState<UngroupedTag[]>()

  const { classes } = useStyles()

  const companyName = props.CompanyNameText || props.BestUrlText

  const renderButtons = typeof props.isSalesforceEnabled === 'boolean' && typeof props.enabledMarketData === 'boolean' && props.CompanyMd5

  const upSmall = useWide('sm')
  const { isDialogOpened: openedReportDialog, openDialog: openReportDialog, closeDialog: closeReportDialog } = useDialog()

  const { payloads } = useSidepanelPayloads()

  useEffect(() => {
    const tagNames = props.Tags?.[0].TagNames
    if (!tagsList && tagNames) {
      setTagsList(
        tagNames.map(({ Category, Tag }: { Category: string; Tag: string } & { Md5: string }) => ({ categoryName: Category, tagName: Tag }))
      )
    }
  }, [props.Tags])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const { identifier, tags } = payloads.value[0]
        if (identifier === id) {
          setTagsList(tags)
        }
      }
    }
    if (tagsList) {
      if (payloads && payloads.action === 'SYNC_TAGS') {
        const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
        if (taggableType === 'companies') {
          const nonDeprecated = tagsList.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          const renamed = nonDeprecated.map(({ categoryName, tagName }) => {
            const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

            const { newTagName = tagName } =
              tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
            return { categoryName: newCategoryName, tagName: newTagName }
          })
          setTagsList(renamed)
        }
      }
    }
  }, [payloads])

  const marketDataActions = useMemo(
    () =>
      [
        {
          name: 'Similar Companies',
          icon: ['fas', 'diagram-venn'],
          link: `/companies/${id}/similar?name=${companyName}`,
          condition: !props.showSimilarCompanies
        },
        {
          name: 'C Suite',
          icon: ['fas', 'user-tie'],
          link: `/companies/${id}/c-suite?name=${companyName}`,
          condition: !props.showSimilarCompanies
        },
        {
          name: 'Report incorrect data',
          icon: ['far', 'message-exclamation'],
          action: openReportDialog
        },
        {
          name: 'Learn more',
          icon: ['far', 'question-circle'],
          externalLink: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data'
        }
      ].filter((action) => (typeof action.condition === 'boolean' ? action.condition : true)),
    [companyName, props.showSimilarCompanies, id]
  )

  const buttonsMap = [
    {
      name: 'Edit',
      icon: ['far', 'edit'],
      link: props.CompanyMd5 ? `/companies/${props.CompanyMd5}/edit` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Merge',
      icon: ['far', 'merge'],
      link: props.CompanyMd5 ? `${Paths._merge}/companies?ids=${props.CompanyMd5}` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Audit',
      icon: ['far', 'list-check'],
      link: props.CompanyMd5 ? `${Paths._companies}/${props.CompanyMd5}/audit` : '',
      condition: !upSmall || (upSmall && renderButtons),
      menuItem: !upSmall
    },
    {
      name: 'Save to Salesforce',
      icon: ['far', 'cloud-upload-alt'],
      link: `${salesForceUrl}/account/${props.CompanyMd5}`,
      condition: props.isSalesforceEnabled && renderButtons,
      menuItem: !upSmall
    }
  ].filter((button) => button.condition)

  const toggleDrawer = () => setDrawerOpen((prevState: boolean) => !prevState)

  const buttons = (
    <>
      <Wide>
        {props.enabledMarketData && renderButtons && (
          <Item component={MarketDataSelect} item={{ menu: { actions: marketDataActions } }} disablePadding />
        )}
      </Wide>

      {buttonsMap
        .filter((el) => !el.menuItem)
        .map((button: { [key: string]: any }) => {
          if (button.component) {
            return <Box key={button.name}>{button.component}</Box>
          }

          return (
            <Box key={button.name}>
              <SidepanelLink linkProps={{ to: button.link }} sidepanel={true}>
                <WideStrict>
                  <Button color="primary" startIcon={<FontAwesomeIcon icon={button.icon} style={{ fontSize: 14 }} />}>
                    {button.name}
                  </Button>
                </WideStrict>
                <Middle>
                  <IconButton color="primary" hint={button.name} loading={!props.CompanyMd5} icon={button.icon} size="small" />
                </Middle>
                <NarrowStrict>
                  <IconButton color="primary" hint={button.name} loading={!props.CompanyMd5} icon={button.icon} size="small" />
                </NarrowStrict>
              </SidepanelLink>
            </Box>
          )
        })}

      <Narrow>
        <SidepanelWide>
          {props.enabledMarketData && renderButtons && (
            <Box mr={-1}>
              <Item component={MarketDataMenuButton} item={{ menu: { actions: marketDataActions } }} disablePadding />
            </Box>
          )}
        </SidepanelWide>
        <SidepanelNarrow>
          <Box mr={-1}>
            <IconButton
              color="primary"
              icon={['far', 'ellipsis-v']}
              hint="Menu"
              size="small"
              loading={!props.CompanyMd5}
              disablePX
              onClick={toggleDrawer}
            />
          </Box>
        </SidepanelNarrow>
      </Narrow>
    </>
  )

  const { linkedinUrl, facebookUrl, twitterUrl } = props.marketData || {}

  const actions = [
    (props.loading || props.CompanyNameText || linkedinUrl) && (
      <IconButton<'a'>
        component="a"
        href={linkedinUrl ? addMissedProtocol(linkedinUrl) : `https://www.linkedin.com/search/results/companies/?keywords=${props.CompanyNameText}`}
        icon={['fab', 'linkedin']}
        color="primary"
        hint="Show Linkedin profile"
        disablePadding
        loading={props.loading}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    ((props.enabledMarketData && !props.marketData) || (!props.loading && twitterUrl)) && (
      <IconButton<'a'>
        component="a"
        icon={['fab', 'twitter']}
        color="primary"
        hint="Show Twitter profile"
        disablePadding
        href={twitterUrl ? addMissedProtocol(twitterUrl) : ''}
        loading={!twitterUrl}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    ((props.enabledMarketData && !props.marketData) || (!props.loading && facebookUrl)) && (
      <IconButton<'a'>
        component="a"
        icon={['fab', 'facebook']}
        color="primary"
        hint="Show Facebook profile"
        disablePadding
        href={facebookUrl ? addMissedProtocol(facebookUrl) : ''}
        loading={!facebookUrl}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    )
  ].filter((action): action is ReactElement => !!action)

  const statusIcons = (
    <>
      {[
        {
          el: (
            <StatusIcon
              status={{ name: 'pending', title: 'Waiting for the next analytics run in order to reflect recent entity alignment changes.' }}
            />
          ),
          condition: !!props.AlignmentIsPending
        }
      ]
        .filter(({ condition }) => condition)
        .map(({ el }) => el)}
    </>
  )

  return (
    <>
      <Card elevation={0} className={classes.summary}>
        <CardContent>
          <ProfileSummary
            title={companyName}
            loading={props.loading}
            url={props.BestUrlText}
            tags={tagsList}
            showAllTagsLink={`${Paths._companies}/${id}/tags?name=${companyName}`}
            editTagsLink={`${Paths._companies}/${id}/tags/edit?name=${companyName}`}
            score={props.Score}
            introducers={props.Introducers}
            buttons={buttons}
            statusIcons={statusIcons}
            actions={actions}
            byline={
              <AnchorLinkLine
                icon={['far', 'globe']}
                loading={props.loading}
                value={props.BestUrlText}
                hint="Visit website"
                href={props.BestUrlText ? addMissedProtocol(props.BestUrlText) : ''}
                rel="noopener noreferrer"
                target="_blank"
              />
            }
          />
        </CardContent>
      </Card>
      <Narrow>
        <DrawerMenu
          open={drawerOpen}
          toggleDrawer={toggleDrawer}
          items={props.enabledMarketData ? [...buttonsMap, ...marketDataActions] : buttonsMap}
        />
      </Narrow>
      <ReportIncorrectDataDialog opened={openedReportDialog} close={closeReportDialog} marketData={JSON.stringify(props.marketData)} />
    </>
  )
}

export default CompanyHeader
